.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
} /* Centralizar item do carrossel do modal */

@media (min-width: 768px) {
    
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(25%);
    }
    
    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
    }
}

/* posicionar os controles do carrossel na extremidade do carrossel e não deixar que fique na frente do modal que abrirá*/
.carousel-control-prev {
    justify-content: flex-start;
    z-index: 0;
}

.carousel-control-next {
    justify-content: flex-end;
    z-index: 0;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    height: 20px;
}

.carousel-indicators .active {
    height: 11px !important;
    width: 10px;
    background-color: rgba(115, 115, 115, 1) !important;
    border-radius: 50%;
    display: inline-block;
    border: none;
    margin-top: 20px;
  }

.disabled-carousel-indicators {
    height: 11px !important;
    width: 10px;
    border: 2px solid rgba(153, 153, 153, 1);
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-top: 20px;
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 0.1rem !important;
    margin-left: 0.2rem !important;
}

.controls-style {
    background-color: transparent;
    width: auto;
    height: min-content;
}

.carousel-inner {
    padding-top: 60px;
}

@media (min-width: 768px) {
        .carousel-inner {
            padding-top: 0;
        }

        .controls-style {
            top: 50%;
        }
}