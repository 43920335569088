.border-color-orange {
    border-color: #f26f21 !important;
}

.margin-left-10percent {
    margin-left: 10% !important;
}

.avatar {
    margin: 0px !important;
}

.height-inherit {
    height: inherit;
}

.white-border {
    border-color: #fafafa;
}

.color-orange {
    color: #f26f21;
}

.grid-wrapper .grid.grid-system {
    margin: 0 0 !important;
}

.color-dark-grey {
    color: #737373;
}

.width-fit-content {
    width: fit-content !important;
}

.width-fill-available {
    width: fill-available;
}

.font-size-17px {
    font-size: 17px !important;
}

.grid-wrapper .grid-container {
    justify-content: center;
}

.border-bottom-orange {
    border-bottom: solid 2px #f26f21;
}

.border-radius-15px-150px-0-0 {
    border-radius: 15px 15px 0 0 !important;
}

.box-shadow-black-2--2-5-0 {
    box-shadow: rgba(0, 0, 0, 0.15) 2px -2px 5px 0 !important;
}

.box-shadow-black-1--3-6-on-button-hover.button-primary-medium:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 1px -3px 6px !important;
}

.box-shadow-black-0-8-11-0 {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 11px 0px;
}

.box-shadow-black-2-0-11-0 {
    box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 11px 0px;
}

.width-150px {
    width: 150px;
}

.white-space-unset {
    white-space: unset !important;
}

.background-color-unset {
    background-color: unset !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.color-red {
    color: background-color-red;
}

.color-dark-yellow {
    color: rgba(203, 183, 0, 1);
}

.color-green {
    color: rgba(0, 203, 81, 1);
}

.font-size-16px {
    font-size: 16px !important;
}

.color-orange-on-badge .badge {
    color: #f26f21;
}

.width-105px {
    width: 105px;
}

.width-90px {
    width: 90px;
}

.width-90percent {
    width: 90%;
}

.font-size-30px {
    font-size: 30px !important;
}

.font-size-25px {
    font-size: 25px !important;
}

.min-width-56px {
    min-width: 56px;
}

.font-size-24px-on-label .label {
    font-size: 24px !important;
}

div.menu-select-wrapper div.menu-select.open {
    margin-top: 40px !important;
}

.width-min-content {
    width: min-content;
}

.width-12px-on-icon button.button.button-sm.icon i {
    width: 12px;
}

.height-12px-on-icon button.button.button-sm.icon i {
    height: 12px !important;
}

.table th {
    padding: 0.75rem 0.5rem;
}

th.padding-sides-030rem, td.padding-sides-030rem {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
}

th.padding-sides-040rem, td.padding-sides-040rem {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
}

.width-max-content {
    width: max-content;
}

.padding-040rem-on-th-and-td th, .padding-040rem-on-th-and-td td{
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
}

.column-label-tooltip {
    position: absolute;
    bottom: 260%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    opacity: 0.9;
    white-space: break-spaces;
    width: 100px;
    text-align: center;
}

.background-color-white {
    background-color: white !important;
}

.narrow-scrollbar-container::-webkit-scrollbar-track {
    margin-left: 150px;
    margin-right: 150px;
    -webkit-box-shadow: inset 0.5px 0.5px 4px rgba(0, 0, 0, 0.155);
    border-radius: 7px;
}

.narrow-scrollbar-container::-webkit-scrollbar-thumb, .highcharts-scrolling::-webkit-scrollbar-thumb {
    background-clip: unset !important;
}

.narrow-scrollbar-container::-webkit-scrollbar, .highcharts-scrolling::-webkit-scrollbar {
	width: 6px !important;
    height: 6px;
}

.highcharts-scrolling::-webkit-scrollbar-track {
    margin-left: 200px;
    margin-right: 200px;
    -webkit-box-shadow: inset 0.5px 0.5px 4px rgba(0, 0, 0, 0.155);
    border-radius: 7px;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.highcharts-tooltip-container {
    z-index: 10 !important;
}

.vertical-align-baseline {
    vertical-align: baseline;
}

.font-weight-bold-on-paragraph p {
    font-weight: bold !important;    
}

.max-height-400px {
    max-height: 400px;
}

.max-height-600px {
    max-height: 600px;
}

.max-width-100percent {
    max-width: 100%;
}

/* Esconder marca d'água do Highcharts  */
.highcharts-credits {
    display: none !important;
}

.z-4 {
    z-index: 4 !important;
}

.z-5 {
    z-index: 5 !important;    
}

.z-10 {
    z-index: 10 !important;
}

.box-shadow-unset {
    box-shadow: unset;
}

.height-175px {
    height: 175px;
}

.width-200px {
    width: 200px;
}

.width-180px {
    width: 180px;
}

.border-radius-8px {
    border-radius: 8px;
}

.border-top-light-grey-solid-1px {
    border-top: solid 1px #BFBFBF;
}

.min-width-fit-content {
    min-width: fit-content; 
}

.box-shadow-unset-on-button.button-primary-medium, .box-shadow-unset-on-button.button-primary-medium:hover, .box-shadow-unset-on-button.button-primary-medium:disabled, .box-shadow-unset-on-button.button-primary-medium:disabled:hover {
    box-shadow: unset !important;
}

.text-align-start-on-button.button-primary-medium {
    text-align: start;
}

.cell-right-border {
    border-right: #BFBFBF 1px solid;
}

.cell-left-border {
    border-left: #BFBFBF 1px solid;
}

.cell-bottom-border {
    border-bottom: #BFBFBF 1px solid !important;
}

.cell-top-border {
    border-top: #BFBFBF 1px solid !important;
}

.table {
    --bs-table-striped-bg: #fafafa;
    border-collapse: separate;
    border-spacing: 0;
    padding: 0 !important;
}

.box-shadow-unset-on-select div.menu-select-wrapper div.textfield-outer:hover {
    box-shadow: unset;
}

.background-color-unset-on-select div.textfield-outer div.ragnarok.textfield-wrapper {
    background-color: unset;
}

.padding-left-0-on-select div.textfield-outer div.ragnarok.textfield-wrapper {
    padding-left: 0;    
}

.white-space-break-spaces {
    white-space: break-spaces;
}

.left-0 {
    left: 0;
}

.width-40px-on-progress .progress.circular {
    width: 40px;
    height: 40px;
}

.bold-text {
    font-weight: bold;
}

/* Refatorar para styled-components: */

.verify-data-paragraph-margin {
    margin-left: calc(24px + 0.5rem);
}

.background-color-red {
    background-color: red;
}

.icon-with-round-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid;
    border-radius: 50%;
    padding: 3px;
}

.color-red {
    color: #ff2462;
}

.background-color-green {
    background-color: rgba(0, 203, 81, 1);
}

.color-white {
    color: white;
}

.filter-warning {
    color: #CBB700;
    margin-bottom: 2px !important
}