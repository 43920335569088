.green-color {
    color: #00cb51;
}

.blue-color {
    color: #2174ef;
}

/* Ajustes no comprimento do select de year e semana comercial: */
.grey-select .year-select div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    width: 140px !important;
}

.year-select div.menu-select-wrapper div.textfield-outer div.textfield-wrapper div.textfield-contents span.textfield-label {
    width: 80px !important;
}

.year-select div.menu-select-wrapper div.textfield-outer {
    width: 140px;
}

.year-select div.menu-select-wrapper {
    width: 140px !important;
}

.grey-select .week-select div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    width: 200px !important;
}

.week-select div.menu-select-wrapper div.textfield-outer div.textfield-wrapper div.textfield-contents span.textfield-label {
    width: 150px !important;
}

.week-select div.menu-select-wrapper div.textfield-outer {
    width: 200px;
}

.week-select div.menu-select-wrapper {
    width: 200px !important;
}

.downloads-options .text-heading-sm {
    color: #737373;
}

.advanced-export-div .dialog-wrapper .dialog {
    max-width: 65vw;
    max-height: 90vh;
    padding: 24px 24px 24px 40px;
    width: unset !important;
}

.downloads-options .vertical-line {
    height: 80%;
    margin-left: -30px;
    top: 60px;
}

.chose-products-div button.button.button-sm span.label {
    font-size: 14px !important;
}

.bigger-icon svg {
    width: 35px;
    height: 35px;
}

.options-div div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    height: 30px !important;
    font-size: 14px;
}

.options-div div.textfield-outer div.ragnarok.textfield-wrapper button {
    margin-top: 12px !important;
}

.options-div button.button.button-sm span.label {
    font-size: 14px !important;
}

.downloads-options {
    padding: 0 24px;
}

@media (min-width: 1200px) {
    .downloads-options-inner {
        padding: 0 3rem 0 3rem;
    }
}

.close-modal-button {
    color: #737373 !important;
    border-color: #737373 !important;
}

.grey-border-on-select-component div.textfield-outer div.ragnarok.textfield-wrapper {
    background-color: #fdfdfd;
    border: 1px solid rgba(17, 24, 39, 0.125);
}

.warning-border div.textfield-outer div.ragnarok.textfield-wrapper {
    background-color: #fdfdfd;
    border: 2px solid #CBB700;
}

.advanced-export-div .warning-border div.textfield-outer div.ragnarok.textfield-wrapper {
    background-color: #fdfdfd;
    border: 2px solid #CBB700;
}

.table .pagination-wrapper, .datalist .pagination-wrapper {
    flex-wrap: wrap;
}

/* Borda do select de semana comercial e year */
.grey-background-warning-border div.textfield-outer div.ragnarok.textfield-wrapper {
    border: 2px solid #CBB700;
    border-radius: 10px;
}

.highcharts-container {
    height: unset;
}

.table-inner-div p {
    font-size: 12.8px;
    margin: 0 !important;
}

.table-inner-div th p {
    font-weight: bold;
}

.table-inner-div th .month-cell {
    font-weight: bold !important;
    font-size: 15.5px !important;
}

.table-inner-div th .wape-cell {
    font-weight: normal !important;
    font-size: 13px !important;
}

/* Tirar as bordas entre as linhas */
thead, tbody, tfoot, tr, td, th {
    border-color: unset;
    border-style: unset;
    border-width: unset;
    text-transform: none !important;
}

table .check.check-outer {
    padding: 0;
    height: unset;
    margin-bottom: 0;
}

table .check.check-outer .check-wrapper .check-control {
    border: 2px solid #737373;
}

/* Borda direita com fade pra destacar colunas fixadas */
.right-border-fade {
    background: #EEEEEE;
    margin: 0 50px;
    width: 100px;
    position: relative;
}

.right-border-fade:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    box-shadow: 7px 0px 13px -15px inset;
    right: -15px;
    z-index: 0;
}

.right-border-fade-with-background-color:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    box-shadow: 7px 0px 13px -15px inset;
    right: -15px;
    background-color: white;
}

.table-fade-left {
    height: 100%;
    width: 40px;
    position: absolute;
    background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important;
    z-index: 6;
}

.table-fade-right {
    height: 100%;
    width: 50px;
    position: absolute;
    right: 10px;
    background: linear-gradient(-90deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 110%) !important;
    z-index: 2;
}

.padding-070rem-on-th-and-td th, .padding-070rem-on-th-and-td td {
    padding-right: 0.70rem !important;
    padding-left: 0.70rem !important;
}

.hide-column-button {
    display: none !important;
}

.category-th:hover .hide-column-button{
    display: block !important;
}

.table-text>p {
    font-size: 14px !important;
    margin-top: 0;
    margin-bottom: 0;
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* Estilos para deixar os headers da tabela sticky */
.table-inner-div {
    max-height: 700px;
}

.table-inner-div th {
    position: sticky !important;
    z-index: 1;
    top: 0;
    background-color: white;
}

.category-header-cell {
    z-index: 3 !important;
}

/* Ajustes para os botões da tabela que levam tooltip não ficarem à frente de outros elementos */
.table-tooltip {
    display: contents !important;
}

.table-tooltip .label {
    position: relative !important;
}

.table-header-tooltip {
    margin-bottom: 13px;
}

/* Aumentar o tamanho do botão de esconder coluna */
.hide-column-button.button.button-sm.icon.no-label {
    padding: 0px !important;
}

.hide-column-button.button.button-sm.icon i {
    justify-content: center;
}

.small-arrow-button .button-primary-medium {
    width: fit-content !important;
    height: fit-content !important;
    min-height: unset !important;
    padding: 0px !important;
}

.calendar-button .button-primary-medium {
    box-shadow: none !important;
    cursor: default;
}

.calendar-button .button-primary-medium:hover {
    box-shadow: none !important;    
}

.textfield-th div.textfield-outer div.ragnarok.textfield-wrapper {
    width: 60px !important;
    height: 25px !important;
}

.textfield-th {
    padding: 0.5rem 0.7rem !important;
}

.textfield-cell {
    overflow: visible;
    border: #dad8d8 1.5px solid;
    box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 3px !important;
    height: 25px;
    border-radius: 4px;
    min-width: 60px;
}

.number-cell-row {
    --bs-gutter-x: unset;
    --bs-gutter-y: unset;
}

.revision-number {
    min-width: 66.66667%;
    width: fit-content;
}

.width-170px-on-select-component div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    width: 170px !important;
}

.width-170px-on-select-component div.textfield-outer {
    width: 170px !important;
}

.width-170px-on-select-component div.menu-select-wrapper {
    width: 170px !important;
}

.width-150px {
    width: 150px;
}

.height-30px-on-select-component div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    height: 30px !important;
}

.margin-top-11px-on-select-component div.textfield-outer div.ragnarok.textfield-wrapper button {
    margin-top: 11px !important;
}

.gray-border-on-select-component div.textfield-wrapper {
    border: 1px rgba(17, 24, 39, 0.125) solid !important;
}

.white-background-on-select-component div.textfield-wrapper {
    background-color: white !important;
}

.font-size-14px-on-select-options span.textfield-label {
    font-size: 14px;
}

.font-size-14px {
    font-size: 14px !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.width-250px-on-select-component div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    width: 250px !important;
}

.width-250px-on-select-component div.textfield-outer {
    width: 250px !important;
}

.width-250px-on-select-component div.menu-select-wrapper {
    width: 250px !important;
}

.textfield-box-shadow-on-hover:hover {
    box-shadow: 0 4px 48px 0 rgba(0,0,0,0.16);
}

.height-30px {
    height: 30px;
}

.gray-border {
    border: 1px solid rgba(17, 24, 39, 0.125);
}

.border-radius-5px {
    border-radius: 5px;
}

.red-border {
    border: 2px red solid;
}

.margin-top-32px {
    margin-top: 32px;
}

.margin-top-28px {
    margin-top: 28px;
}

.red-border-on-select-component div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    border: 2px red solid;
}

.justify-content-between-on-switch .switch-outer {
    justify-content: space-between !important;
}

.aidron-ds-button-styles {
    background-color: transparent;
    border: 2px solid #f26f21;
    color: #f26f21;
    min-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: all 160ms cubic-bezier(0.32, 0.01, 0.32, 1);
    font-weight: 700;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 500px;
    box-shadow: rgba(0, 0, 0, 0.3) 1.95px 1.95px 5px;
}

.aidron-ds-button-styles:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 1.95px 1.95px 10px;
}

.light-gray-text {
    color: #e9e8e8;
}