.calendar-page .react-calendar {
    width: 320px;
    font-size: 14px !important;
}

.calendar-page button.react-calendar__navigation__arrow {
    display: none;
}

.calendar-page .react-calendar button {
    color: black;
}

.calendar-page .react-calendar__tile {
    padding: 7px 0px;
}

.calendar-page .react-calendar__month-view__weekdays__weekday {
    padding: 0;
}

.calendar-page .react-calendar {
    border: none;
    background-color: transparent;
    padding: 8px;
    padding-bottom: 3px;
}

.calendar-page .month-name-div {
    background-color: #f26f21;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
}

.calendar-page .react-calendar__month-view__weekdays {
    text-transform: capitalize;
    font-size: 0.9em;
}

.calendar-page abbr[title] {
    text-decoration: none;
    cursor: default;
}

.calendar-page .react-calendar__month-view__weekNumbers .react-calendar__tile {
    font-size: 1em;
    color: #f26f21;
}

.calendar-page .react-calendar__tile--active {
    background: white;
}

.calendar-page .react-calendar__tile--active:enabled:hover, .calendar-page .react-calendar__tile--active:enabled:focus {
    background: white;
}

.react-calendar__tile--now:enabled:hover {
    background: #3d67f1;
}

.react-calendar__tile--now:enabled:focus {
    background: #3d67f1 !important;
    color:white;
}

.calendar-page .react-calendar__tile--active:enabled:focus {
    background: white;
    color: black;
}

.calendar-page button:not(:disabled), .calendar-page [type="button"]:not(:disabled), .calendar-page [type="reset"]:not(:disabled), .calendar-page [type="submit"]:not(:disabled) {
    cursor: default !important;
}

.calendar-page .react-calendar__tile--now {
    background: #3d67f1;
    color: white !important;
    border-radius: 4px;
}

.calendar-page .year-input-height {
    min-height: 110px;
}

/* .calendar-page div.textfield-outer div.ragnarok.textfield-wrapper, .calendar-page div.textfield-outer div.ragnarok.textfield-wrapper.active {
    width: 110px !important;
} */

div.textfield-outer div.ragnarok.textfield-wrapper.active span.textfield-label {
    font-size: 14px !important;
}